<template>
  <div v-if="event && race">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header :event="event"/>
          
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-4 py-2">
          Leaderboard <RaceSelector :event="event" :race="race" routeName="eventmanagerRace" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn v-if="race && event.published" :to="{name: 'race', params: {id: this.event.id, raceId: this.race.id}}" class="mr-4">
            <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
            View
          </v-btn>
          <v-btn v-if="race" @click="showEditRace" class="mr-0">
            <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
            Edit
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon title="More options" >fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="deleteRace()">
                <v-list-item-icon><v-icon>fal fa-trash</v-icon></v-list-item-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar>
      <v-container class="info-container grey lighten-5 py-0">
        <v-row>
          <v-col cols="6" sm="4">
            <div class="label">ID</div>
            <div class="value"><pre>{{race.id}}</pre></div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Scoring</div>
            <div class="value">
              <v-icon small class="mr-2">{{ $helpers.getScoringIcon(race.scoring) }}</v-icon> 
              {{ $t('events.rules.scoring-methods-short.' + race.scoring) }}
              <span v-if="race.badge_scoring" class="text-muted">
                ({{ $helpers.displayText(siteData.badge_scoring_methods, race.badge_scoring, 'Default Scoring') }})
              </span>
              <div v-if="event.locked || race.locked" class="text-muted">
                <v-icon x-small color="">fa-lock-alt</v-icon> Results are locked
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Goal</div>
            <div class="value">
              {{ $helpers.getGoalDistanceForDisplay($options, race, race.dist, event.unit) }}
              <span v-if="race.overshoot" class="text-muted">(overshoot enabled)</span>
              <div v-if="race.collective">
                Collective goal:
                <span v-if="race.collective_goal">{{ $helpers.getGoalDistanceForDisplay($options, race, race.collective_goal, event.unit)}}</span>
                <span v-else>Same as individual.</span>
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Type</div>
            <div class="value">
              {{ $helpers.displayText(siteData.race_types, race.type, 'Challenge') }}
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Visibility</div>
            <div class="value">
              {{ $helpers.displayText(siteData.race_visibility, race.visibility, 'Visible') }}
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Individual / Team</div>
            <div class="value">
              <span v-if="race.team">{{ race.team }}</span>
              <span v-else>Individual</span>
              <v-btn v-if="race.team == 'RELAY_TIME_SLOTS' || race.team == 'RELAY_DISTANCE_SLOTS'" icon small color="primary" class="ml-2" :disabled="!eventUtil.isProPlan()" :to="{name: 'eventmanagerRaceRelaySlots', params: { id:event.id, raceId: race.id }}">
                <v-icon small>fa-cogs</v-icon>
              </v-btn>

            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Allowed Activity Types</div>
            <div class="value">
              <span v-if="!race.activity_types || race.activity_types.length==0">All</span>
              <span v-else v-for="(type, idx) in race.activity_types" :key="idx">
                <v-icon :title="$t('profile.activities.types.'+type)">{{ $helpers.getActivityIcon(type) }}</v-icon>
                <span v-if="idx != race.activity_types.length-1" class="mx-1">{{ $t('shared.or') }}</span>
              </span>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Activity Filter</div>
            <div class="value">
              <div v-if="race.min_dist">
                Activities: {{ $helpers.getDistanceForDisplay($options, race, race.min_dist, event.unit) }} - {{ $helpers.getDistanceForDisplay($options, race, race.max_dist, event.unit) }}
              </div>
              <div v-if="race.from">
                {{race.from | localDate("L")}} - {{race.till | localDate("L")}}
              </div>
              <div v-if="!race.min_dist && !race.from">
                No filter defined.
              </div>
            </div>
          </v-col>
          <v-col cols="6" sm="4">
            <div class="label">Import ID</div>
            <div class="value">
              <pre>{{race.import_id || '-'}}</pre>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-alert tile v-if="!event.published" type="info" class="mb-0">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
      <v-divider/>
      <v-container class="grey lighten-3">
      <v-card-text v-if="false">
        View and manage this leaderboard.
      </v-card-text>
      <v-row class="mx-2">
        <v-col v-if="eventUtil.supportsBadges()" cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-shield</v-icon> Badges</v-card-title>
            <v-card-text>Manage the badges for this leaderboard.</v-card-text>
            <v-card-actions>
              <v-btn text block :to="{name: 'eventmanagerRaceBadges', params: { id:event.id, raceId: race.id }}">Manage Badges</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col v-if="eventUtil.supportsSplits()" cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-bars</v-icon> Splits</v-card-title>
            <v-card-text>Manage the legs and splits for this race.</v-card-text>
            <v-card-actions>
              <v-btn text block :to="{name: 'eventmanagerRaceSplits', params: { id:event.id, raceId: race.id }}">Manage Splits</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-poll-people</v-icon> Leaderboard</v-card-title>
            <v-card-text>Manage and customize the leaderboards and results.</v-card-text>
            <v-card-actions style="flex-direction:column;">
              <v-btn text block :to="{name: 'eventmanagerRaceResultColumns', params: { id:event.id, raceId: race.id }}">Configure Columns</v-btn>
              <v-btn text block :to="{name: 'eventmanagerRaceResults', params: { id:event.id, raceId: race.id }}">Manage Results</v-btn>
              <v-btn text block :disabled="!eventUtil.supportsResultsUpload()" @click="uploadResults()">Upload Results</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <v-card>
            <v-card-title><v-icon class="mr-2">fal fa-flag-checkered</v-icon> Race Results</v-card-title>
            <v-card-text>Upload race results for hybrid and in-person races.</v-card-text>
            <v-card-actions style="flex-direction:column;">
            </v-card-actions>
          </v-card>
        </v-col>
 -->
        <v-col cols="12" sm="4">
          <v-card color="white">
            <v-card-title><v-icon class="mr-2">fal fa-map</v-icon> Geography</v-card-title>
            <v-card-text>Upload virtual course map and geo-fences. Only available for some challenge types.</v-card-text>
            <v-card-actions style="flex-direction:column;">
              <v-btn text block :disabled="!eventUtil.supportsCourseMap()" @click="uploadGpxTrack()">Upload GPX Track</v-btn>
              <v-btn text block :disabled="!eventUtil.supportsGeoFence()" :to="{name: 'eventmanagerRaceGeofence', params: { id:event.id, raceId: race.id }}">Configure Geo-Fence</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      </v-container>

    </v-card>
    <RaceDialog ref="raceDialog" :event="event" @save="eventUtil.requestSave"/>
    <v-dialog v-if="event && race" v-model="gpxDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload virtual course</span>
        </v-card-title>
        <v-card-text>
          <p>Upload a virtual course track. You can upload any gpx file. The uploaded track will be scaled to match the distance of this leaderboard.</p>
          <v-alert v-if="race.route" type="info">Note: uploading a track will overwrite the current track.</v-alert>
          
          <ActivityUploadForm :event-id="event.id" :race-id="race.id" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGpxDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-if="event && race" v-model="resultsDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload race results for {{race.name}}</span>
        </v-card-title>
        <v-card-text>
          <p>Upload an Excel or csv file with race results.</p>
          
          <ExcelUploadForm :event="event" :event-id="event.id" :race-id="race.id" @uploaded="resultsDialog=false;" type="RACE_RESULTS"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resultsDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
  </div>
</template>

<script>
import Header from './_Header.vue';
import RaceSelector from './_RaceSelector.vue'
import EventUtil from "@/util/eventUtil";
import RaceDialog from './_RaceDialog.vue'
import ActivityUploadForm from "@/components/ActivityUploadForm";
import ExcelUploadForm from "@/components/ExcelUploadForm";
import siteData from '@/data/site.json'

export default {
  name: "RaceResults",
  components: {
    Header,
    RaceSelector,
    RaceDialog,
    ActivityUploadForm,
    ExcelUploadForm,
  },
  props: {
    tenant: Object,
    event: Object,
    race: Object,
  },
  data() {
    return {
      gpxDialog: false,
      resultsDialog: false,
      siteData,
    };
  },
  async mounted() {
  },
  methods: {
    async showEditRace() {
      await this.eventUtil.refresh();
      console.log('edit race', this.race.id, 'dialog', this.$refs.raceDialog);
      const item = this.event.races.find(x => x.id == this.race.id);
      this.$refs.raceDialog.editRace(item);
    },
    async deleteRace() {
      await this.eventUtil.refresh();
      const item = this.eventUtil.event.races.find(x => x.id == this.race.id);
      const index = this.eventUtil.event.races.indexOf(item);
      confirm(`Are you sure you want to DELETE LEADERBOARD ${item.name} AND ALL RESULTS?`) && this.eventUtil.event.races.splice(index, 1) && this.eventUtil.requestSave() && this.$router.push({name: 'eventmanagerView', params: {id: this.eventUtil.event.id}});
    },
    uploadGpxTrack() {
      this.gpxDialog = true
    },
    closeGpxDialog() {
      this.gpxDialog = false;
    },
    uploadResults() {
      this.resultsDialog = true
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.race.name, disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
  },

};
</script>
<style lang="scss">
</style>

